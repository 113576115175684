import React, { useState, useEffect } from 'react';
import Navbar from '../Navbar/Navbar';
// import './past-order.css';
import Spinner from 'react-bootstrap/Spinner';
import { getOrdersList, getGiftOrdersList, getMe } from '../../shared/apis';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { ConvertPKRtoUSD } from '../../App';
import Footer from '../Footer/footer'
import superman from '../../assets/images/superman.png'
import './my-gifts.css'
import sticker from '../../assets/images/Image.png'

function MyGifts() {
    const [orders, setOrders] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const [giftOrder, setGiftOrder] = useState([]);
    const storedCountry = localStorage.getItem('country');

    const init = async () => {
        let me = await getMe();
        // console.log(me);
        setIsLoading(true);
        let allOrders = await getOrdersList(0, 20, 'COMPLETED');
        setOrders(allOrders.data.orders);
        let giftOrders = await getGiftOrdersList(20, 0, 'COMPLETED', me?.data?.user?._id);
        setGiftOrder(giftOrders?.data?.giftOrders);
        console.log(giftOrders);
        setIsLoading(false);
    };

    useEffect(() => {
        init();
    }, []);

    const handleCardClick = (orderId) => {
        navigate(`/order-details-main/${orderId}`);
    };

    return (
        <>
            <Navbar />
            <div className="past-orders main-gifts-page">
                <div className="past-orders-wrapper">
                    <h2 className='past-orders-heading'>My Gifts</h2>
                </div>
                <div className="orders-content past-gifts">
                    {isLoading &&
                        <div className="slider-content d-flex justify-content-center align-items-center">
                            <div className='mt-5' style={{ textAlign: 'center' }}>
                                <Spinner animation="border" variant="danger" />
                            </div>
                        </div>
                    }
                    {!isLoading &&
                        <div className="slider-content">
                            {orders.length === 0 && giftOrder.length === 0 ? (
                                <p>No Gits Received yet</p>
                            ) : (
                                <>
                                    {/* {orders.map((order) => (
                                        <div className="past-orders-card" key={order._id} onClick={() => handleCardClick(order._id)}>
                                        <div className="past-orders-content">
                                        <div className='past-orders-left'>
                                        <img src={order.orderItems[0].dish.picture} className="past-orders-img" />
                                        <div className="orders-body-content">
                                        <p className='past-orders-title'>{order?.chef?.restaurantName ? order.chef.restaurantName.substring(0, 15) : ''}</p>
                                        <p className='past-orders-time'>{moment(order.updatedAt).format('MMMM Do YYYY, h:mm:ss a')}</p>
                                        </div>
                                        </div>
                                        <div className='past-orders-right'>
                                        <p className='past-orders-price'>{ConvertPKRtoUSD(order.total.toFixed(2))}</p>
                                        </div>
                                            </div>
                                            </div>
                                            ))}
                                            {giftOrder.map((order) => (
                                                <div className="past-orders-card" key={order._id} onClick={() => handleCardClick(order._id)}>
                                                <div className="past-orders-content">
                                                <div className='past-orders-left'>
                                                    <img src={order?.orderItems[0]?.gift.picture} className="past-gift-orders-img" />
                                                    <div className="orders-body-content">
                                                    <p className='past-orders-title'>{order?.orderItems[0]?.gift?.name ? order?.orderItems[0]?.gift?.name.substring(0, 15) : ''}</p>
                                                    <p className='past-orders-time'>{moment(order.updatedAt).format('MMMM Do YYYY, h:mm:ss a')}</p>
                                                    </div>
                                                    </div>
                                                    <div className='past-orders-right'>
                                                    <p className='past-orders-price'>{ConvertPKRtoUSD(order.total.toFixed(2))}</p>
                                                    </div>
                                                    </div>
                                                    </div>
                                                    ))} */}
                                    <div className="my-gifts-page">
                                        <div className="greetings-card">
                                            <div className='greetings-card-sec1'>
                                                <img src={superman} className='superman-greetings' />
                                                <h1 className="greetings-heading">Hi Greetings!</h1>
                                                <p className="gifts-greetings-desc">Here something for u from your loved ones!</p>
                                                <p className="gifts-greetings-desc">From: <span className='reciever-name'>Muhammad Imran</span></p>
                                                <textarea className='greetings-textarea' placeholder='Text' />
                                            </div>
                                            <div className='greetings-card-sec2'>
                                                <h1 className="vid-msg-heading">Video Message</h1>
                                                <textarea className='greetings-textarea' placeholder='Video' />
                                                <img src={sticker} alt="" className="greetings-img" />
                                            </div>
                                        </div>
                                        <button className='greetings-card-btn'>Mark as read</button>
                                    </div>
                                </>
                            )}
                        </div>
                    }
                </div>
            </div>
            <Footer />
        </>
    );
}

export default MyGifts;
