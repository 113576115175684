import Navbar from "../../Navbar/Navbar";
import CookieConsent from "../../cookies-consent/cookies-consent";
import "./LandingHome.css";
import salad from '../../../assets/images/salad.png'
import landingImg from '../../../assets/images/landing-img.png'
import forunpointText from '../../../assets/images/forunpoint-text.png'
import rider from '../../../assets/images/rider.png'
import { useNavigate } from 'react-router-dom';
import Lahore from '../../../assets/images/lhr.png'
import Karachi from '../../../assets/images/khr.png'
import Islamabd from '../../../assets/images/isl.png'
import Rawalpindi from '../../../assets/images/rwp.png'


const LandingHome = () => {
  const navigate = useNavigate();
  const handleCityClick = (city) => {
    navigate(`/restaurants/city/${city}`);

  };
  return (
    <div className="landing-home-container">
      <Navbar />
      <div className="landing-page">
        <div className="image-sec"></div>
        <div className="landing-content">
          <img src={forunpointText} alt="Forunpoint Text" className="forunpoint-text" />
          <h1 className="landing-heading">Your Desire One Tap Away!</h1>
          {/* <p className="landing-description">Chutki Bajate Forun Hazir!</p> */}
          <p className="landing-description">FREE DELIVERY ON FOOD AND GIFTS!</p>
          <h1 className="landing-headings">Send food and gifts together to the following cities.</h1>

        </div>
        <img src={salad} className="salad-img" alt="Salad" />
        <img src={landingImg} className="dish-img" alt="Dish" />
        <img src={rider} className="rider-img" alt="Rider" />
        <div className="row landingcard2">
          <div className="col-md-6 col-lg-5">
            <div className="landing-card">
              <div className="card-body">
                <div className="input-group md-form form-sm form-2 pl-0">
                  <div class="button-container">
                    <button class="city-button active" onClick={() => handleCityClick('Lahore')}><img src={Lahore} alt="White Icon" className="icon-small" /> Lahore</button>
                    <button class="city-button" onClick={() => handleCityClick('Karachi')} ><img src={Karachi} alt="White Icon" className="icon-small" /> Karachi</button>
                    <button class="city-button" onClick={() => handleCityClick('Islamabad')} ><img src={Islamabd} alt="White Icon" className="icon-small" /> Islamabad</button>
                    <button class="city-button" onClick={() => handleCityClick('Rawalpindi')} ><img src={Islamabd} alt="White Icon" className="icon-small" />Rawalpindi</button>
                  </div>

                  <div className="input-group-append"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CookieConsent />
    </div>
  );
};

export default LandingHome;
