import React, { useRef, useEffect, useState } from 'react';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/footer';
import './restaurant-home.css';
import { IoIosStar, IoIosAdd } from "react-icons/io";
import CartComponent from '../home/cart/cart';
import { useParams } from "react-router-dom";
import { getAllDishes, getMe } from '../../shared/apis';
import DetailsPopup from '../dish-detail-popup/Dish-popup';
import Spinner from 'react-bootstrap/Spinner';
import { settings4 } from './settings';
import Toast from 'react-bootstrap/Toast';
import { ConvertPKRtoUSD } from '../../App';
import { Helmet } from 'react-helmet';

function RestaurantHome() {
    const sliderRef = useRef(null);
    const [dishes, setDishes] = useState([]);
    const [restaurant, setRestaurant] = useState({});
    const { id } = useParams();
    const [showToast2, setShowToast2] = useState(false);
    // const [coordinates] = useState([33.148150, -96.763130]);
    // const [coordinates] = useState([31.3728262, 74.1592163]);
    const [cartItems, setCartItems] = useState([]);
    const [giftItems, setGiftItems] = useState([]);
    const [selectedDish, setSelectedDish] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [slidesToShow, setSlidesToShow] = useState(3);
    const [isLoading, setIsLoading] = useState(false);
    const set1 = settings4;
    const [showToast, setShowToast] = useState(false);
    const [showToastS, setShowToastS] = useState(false);
    const storedCountry = localStorage.getItem('country');
    const [isTaxpayable, setIsTaxpayable] = useState();
    const [scrollPosition, setScrollPosition] = useState(0);
    const [userLoc, setUserLoc] = useState({});
    const [isLoginActive, setLoginActive] = useState(false);
    const [open, setOpen] = useState(false);
    const onCloseModal = () => {
        setOpen(false);
        setLoginActive(true);
    };
    const toastStyle = {
        position: 'fixed',
        top: `${scrollPosition}px`,
        right: '10px',
        zIndex: 1000,
    };
    const horiSliderRef = useRef(null);
    // const [startX, setStartX] = useState(null);
    // const [scrollLeft, setScrollLeft] = useState(0);
    const [currentTimings, setCurrentTimings] = useState([]);


    const searchParams = new URLSearchParams(window.location.search);
    const dishParamValue = searchParams.get('dish');

    // const handleTouchStart = (event) => {
    //     setStartX(event.touches[0].clientX);
    // };

    // const handleTouchMove = (event) => {
    //     if (!startX) return;

    //     const currentX = event.touches[0].clientX;
    //     const scrollX = startX - currentX;
    //     sliderRef.current.scrollLeft = scrollLeft + scrollX;
    // };

    // const handleTouchEnd = () => {
    //     setStartX(null);
    //     setScrollLeft(sliderRef.current.scrollLeft);
    // };
    const showToastFunction = () => {
        setShowToast(true);
        setTimeout(() => {
            setShowToast(false);
        }, 3000);
    };
    const handleLoginClick = () => {
        console.log('runug')
        setLoginActive(true);
        document.getElementById('login-btn').style.background = 'var(--Gradient-Primary, linear-gradient(180deg, #D4110F 0%, #FF4D00 100%))';
        document.getElementById('login-btn').style.color = '#FFFFFF';
        document.getElementById('signup-btn').style.background = '#FFFFFF';
        document.getElementById('signup-btn').style.color = '#d4110f';
        setOpen(true);
    };

    const init = async () => {
        setIsLoading(true);
        scrollToTop();
        let dishes = await getAllDishes(id);
        console.log('dishes',dishes)
        let usddishes = [];
        let caddishes = [];

        const storedCountry = localStorage.getItem('country');
        if (storedCountry === 'US') {
            dishes.data.dishes.map(dish => {
                dish.price = dish.priceUSD;
                if (dish?.sizes?.[0]?.price) {
                    dish.sizes[0].price = dish.sizes[0].priceUSD;
                }
                usddishes.push(dish);
            });
        } else if (storedCountry === 'CA') {
            dishes.data.dishes.map(dish => {
                dish.price = dish.priceCAD;
                if (dish?.sizes?.[0]?.price) {
                    dish.sizes[0].price = dish.sizes[0].priceCAD;
                }
                caddishes.push(dish);
            });
        }
        else {
            usddishes = dishes.data.dishes;
        }
        if (dishParamValue) {
            let dish = dishes?.data?.dishes.find(item => item._id === dishParamValue);
            if (dish) {
                setSelectedDish(dish);
                setIsModalOpen(true);
            }
        }
        if (storedCountry === 'US') {

            setDishes(usddishes);
        } else if (storedCountry === 'CA') {
            setDishes(caddishes);

        }
        setRestaurant(dishes.data.dishes[0].user);
        setIsLoading(false);
        let me = await getMe();
        const userAddress = JSON.parse(localStorage.getItem('UserAddress'));
        let latlng = {};
        if (me.data.user.location?.coordinates) {
            latlng.lat = me.data.user.location.coordinates[1];
            latlng.lng = me.data.user.location.coordinates[0];
        } else if (userAddress) {
            latlng.lat = userAddress.location.coordinates[1];
            latlng.lng = userAddress.location.coordinates[0];
        }
        setUserLoc(latlng);
        setDishes(usddishes);
        if (storedCountry === 'US') {

            setDishes(usddishes);
        } else if (storedCountry === 'CA') {
            setDishes(caddishes);

        }
        setRestaurant(dishes.data.dishes[0].user);
        setIsLoading(false);
        setIsTaxpayable(dishes.data.dishes[0].user.isTaxPayable);

        const now = new Date();
        const day = now.toLocaleDateString('en-US', { weekday: 'long' });
        const hour = now.getHours();
        const minute = now.getMinutes();
        const ampm = hour >= 12 ? 'PM' : 'AM';

        const formattedHour = hour % 12 || 12;
        const formattedMinute = minute < 10 ? `0${minute}` : minute;

        let restaurantOpen = false; // Flag to track if the restaurant is open
        dishes.data.dishes[0].user.slots.forEach(slot => {
            if (slot.day === day) {
                setCurrentTimings(slot.timings);
                // console.log(slot.timings);

                slot.timings.forEach(time => {
                    // console.log('Open', time.open);
                    // console.log('Close', time.close);

                    const [openHour, openMinute] = time.open.split(' ')[0].split(':').map(Number);
                    const [closeHour, closeMinute] = time.close.split(' ')[0].split(':').map(Number);
                    const openAmpm = time.open.split(' ')[1];
                    const closeAmpm = time.close.split(' ')[1];

                    // Convert the time to 24-hour format for comparison
                    const currentHour24 = hour;
                    const openHour24 = openAmpm === 'PM' && openHour !== 12 ? openHour + 12 : openAmpm === 'AM' && openHour === 12 ? 0 : openHour;
                    const closeHour24 = closeAmpm === 'PM' && closeHour !== 12 ? closeHour + 12 : closeAmpm === 'AM' && closeHour === 12 ? 0 : closeHour;

                    const currentTime = currentHour24 * 60 + minute;
                    const openTime = openHour24 * 60 + openMinute;
                    const closeTime = closeHour24 * 60 + closeMinute;

                    // console.log(`Current time: ${currentTime}`);
                    // console.log(`Open time: ${openTime}`);
                    // console.log(`Close time: ${closeTime}`);

                    if (currentTime >= openTime && currentTime <= closeTime) {
                        restaurantOpen = true; // If any time slot is open, set the flag to true
                    }
                });
            }
        });

        // After checking all time slots for the day
        // if (restaurantOpen) {
        //     console.log('Restaurant is currently open.');
        // } else {
        //     console.log('Restaurant is currently closed.');
        //     setShowToastS(true);
        // }

        // console.log(`Day: ${day}`);
        // console.log(`Time: ${formattedHour}:${formattedMinute} ${ampm}`);


    };

    const openModal = (dish) => {

        setShowToast2(true);
        if (showToastS) {
            setIsModalOpen(false);
        } else {
            setIsModalOpen(true)
        }
        setSelectedDish(dish);
        setTimeout(() => {
            setShowToast2(false);
        }, 3000);
    };

    const closeModal = () => {
        setSelectedDish(null);
        setIsModalOpen(false);
    };

    useEffect(() => {
        setCartItems(JSON.parse(localStorage.getItem('cartItems')));
        setGiftItems(JSON.parse(localStorage.getItem('giftItems')));
        init();
        const handleResize = () => {
            if (sliderRef.current) {
                const containerWidth = sliderRef.current.clientWidth;
                const newSlidesToShow = Math.floor(containerWidth / 300);
                setSlidesToShow(newSlidesToShow);
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const handleScroll = (event) => {
            setScrollPosition(window.pageYOffset);
            if (sliderRef.current && sliderRef.current.contains(event.target)) {
                sliderRef.current.scrollTop += event.deltaY;
            }
        };

        window.addEventListener('wheel', handleScroll);

        return () => {
            window.removeEventListener('wheel', handleScroll);
        };
    }, []);

    // const addToCart = (item) => {
    //     const existingItem = cartItems.find((cartItem) => cartItem.id === item.id);
    //     if (existingItem) {
    //         setCartItems((prevCartItems) =>
    //             prevCartItems.map((cartItem) =>
    //                 cartItem.id === item.id
    //                     ? { ...cartItem, quantity: cartItem.quantity + 1 }
    //                     : cartItem
    //             )
    //         );
    //     } else {
    //         setCartItems((prevCartItems) => [...prevCartItems, item]);
    //     }
    // };
    // const addToCart = (item) => {
    //     setCartItems((prevCartItems) => {
    //         console.log(cartItems)
    //         const existingItem = prevCartItems.find(
    //             (cartItem) => cartItem.id === item.id && cartItem.size === item.size
    //         );

    //         if (existingItem) {
    //             return prevCartItems.map((cartItem) =>
    //                 cartItem.id === item.id && cartItem.size === item.size
    //                     ? { ...cartItem, quantity: cartItem.quantity + 1 }
    //                     : cartItem
    //             );
    //         } else {
    //             return [...prevCartItems, { ...item, quantity: 1 }];
    //         }
    //     });

    //     // Update localStorage after state update
    //     setTimeout(() => {
    //         localStorage.setItem('cartItems', JSON.stringify(cartItems));
    //     }, 600);
    // };

    // const addToCart = (item) => {
    //     let currentCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
    //     console.log(currentCartItems)
    //     if (currentCartItems && currentCartItems?.length > 0 && currentCartItems[0].cartItems.dish.user._id !== item.cartItems.dish.user._id) {
    //         alert('You can only add items from one restaurant at a time.');
    //         return;
    //     }

    //     let updatedCartItems = currentCartItems.map((cartItem) => {
    //         if (cartItem.id === item.id && cartItem.size === item.size) {
    //             return { ...cartItem, quantity: cartItem.quantity + 1 };
    //         }
    //         return cartItem;
    //     });

    //     // if (!updatedCartItems.some(cartItem => cartItem.id === item.id && cartItem.size === item.size)) {
    //     //     // console.log("Item not found in cart, adding new item:", item);
    //     //     updatedCartItems = [...updatedCartItems, { ...item, quantity: 1 }];
    //     // }
    //     if (!updatedCartItems.some(cartItem => cartItem.id === item.id && cartItem.size === item.size)) {
    //         updatedCartItems = [...updatedCartItems, { ...item, quantity: item.quantity}];
    //     }

    //     localStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
    //     setCartItems(updatedCartItems);
    // };
    // const addToCart = (item) => {
    //     console.log(item)
    //     let currentCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];

    //     // Check if items from different restaurants
    //     if (currentCartItems.length > 0 && currentCartItems[0].cartItems?.dish?.user?._id !== item.cartItems?.dish?.user?._id) {
    //         alert('You can only add items from one restaurant at a time.');
    //         return;
    //     }

    //     // Check for existing item with the same id and size
    //     let existingItemIndex = currentCartItems.findIndex(cartItem => 
    //         cartItem.id === item.id && cartItem.size?.size === item.cartItems?.size?.size
    //     );

    //     let updatedCartItems;

    //     if (existingItemIndex !== -1) {
    //         // If the item with the same id and size exists, increase the quantity
    //         updatedCartItems = currentCartItems.map((cartItem, index) => {
    //             if (index === existingItemIndex) {
    //                 return { ...cartItem, quantity: cartItem.quantity + 1 };
    //             }
    //             return cartItem;
    //         });
    //     } else {
    //         // If the item with the same id but different size doesn't exist, add it as a new item
    //         updatedCartItems = [...currentCartItems, { ...item, quantity: 1 }];
    //     }
    //     console.log('no',updatedCartItems)

    //     // Save updated cart items to local storage and update state
    //     localStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
    //     setCartItems(updatedCartItems);
    // };

    const addToCart = (item) => {
        let currentCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];

        if (currentCartItems.length > 0 && currentCartItems[0].cartItems?.dish?.user?._id !== item.cartItems?.dish?.user?._id) {
            alert('You can only add items from one restaurant at a time.');
            return;
        }

        let existingItemIndex = currentCartItems.findIndex(cartItem =>
            cartItem.id === item.id && cartItem.size?.size === item.cartItems?.size?.size
        );

        let updatedCartItems;

        if (existingItemIndex !== -1) {
            updatedCartItems = currentCartItems.map((cartItem, index) => {
                if (index === existingItemIndex) {
                    return { ...cartItem, quantity: cartItem.quantity + item.quantity };
                }
                return cartItem;
            });
        } else {
            updatedCartItems = [...currentCartItems, { ...item, quantity: item.quantity || 1 }];
        }

        localStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
        setCartItems(updatedCartItems);
    };


    const haversine = (lat1, lon1, lat2, lon2) => {
        lat1 = toRadians(lat1);
        lon1 = toRadians(lon1);
        lat2 = toRadians(lat2);
        lon2 = toRadians(lon2);

        let dlat = lat2 - lat1;
        let dlon = lon2 - lon1;

        let a = Math.sin(dlat / 2) ** 2 + Math.cos(lat1) * Math.cos(lat2) * Math.sin(dlon / 2) ** 2;
        let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const R = 6371;
        let distance = R * c;
        return distance.toFixed(2);
    }

    const toRadians = (degrees) => {
        return degrees * (Math.PI / 180);
    }
    const getDishesByCategories = async (category) => {
        let dishes = await getAllDishes(id);

        let usddishes = [];
        let caddishes = [];
        // const filteredDishes = dishes.data.dishes.filter(dish => dish.category === category);

        // Filter dishes by category and set price based on country
        const filteredDishes = dishes.data.dishes.filter(dish => dish.category === category)
            .map(dish => {
                if (storedCountry === 'US') {
                    dish.price = dish.priceUSD;
                    if (dish?.sizes?.[0]?.price) {
                        dish.sizes[0].price = dish.sizes[0].priceUSD;
                    }
                    usddishes.push(dish);
                } else if (storedCountry === 'CA') {
                    dish.price = dish.priceCAD;
                    if (dish?.sizes?.[0]?.price) {
                        dish.sizes[0].price = dish.sizes[0].priceCAD;
                    }
                    caddishes.push(dish);
                } else {
                    // Default case, if no specific country is matched
                    usddishes.push(dish);
                }
                return dish;
            });

        // Update state with filtered dishes
        setDishes(filteredDishes);
        // setDishes(filteredDishes);
    };

    // const restaurantSearch = async (input) => {
    //     let dishes = await getAllDishes(id);
    //     setDishes(dishes.data.dishes);
    //     const regex = new RegExp(input, 'i');
    //     setDishes(dishes.data.dishes.filter(item => regex.test(item.dishName)));
    // }
    const restaurantSearch = async (input) => {
        let dishes = await getAllDishes(id);
        let usddishes = [];
        let caddishes = [];
    
        const storedCountry = localStorage.getItem('country');
        const regex = new RegExp(input, 'i');
    
        const filteredDishes = dishes.data.dishes
            .filter(item => regex.test(item.dishName))
            .map(dish => {
                if (storedCountry === 'US') {
                    dish.price = dish.priceUSD;
                    if (dish?.sizes?.[0]?.price) {
                        dish.sizes[0].price = dish.sizes[0].priceUSD;
                    }
                    usddishes.push(dish);
                } else if (storedCountry === 'CA') {
                    dish.price = dish.priceCAD;
                    if (dish?.sizes?.[0]?.price) {
                        dish.sizes[0].price = dish.sizes[0].priceCAD;
                    }
                    caddishes.push(dish);
                } else {
                    // Default case if no country match
                    usddishes.push(dish);
                }
                return dish;
            });
    
        setDishes(filteredDishes);
    };
    

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const renderStars = (rating) => {
        const stars = [];
        for (let i = 0; i < 5; i++) {
            if (i < rating) {
                stars.push(<IoIosStar key={i} className='star-ico' />);
            } else {
                stars.push(<IoIosStar key={i} className='star-ico' id='black-ico' />);
            }
        }
        return stars;
    };

    return (
        <div className='main-restaurant-page'>
            {id && restaurant &&
                <Helmet>
                    <title>{`Delicious Dishes at ${restaurant.restaurantName} | Explore the Menu`}</title>
                </Helmet>
            }
            <Navbar handleLoginClick={handleLoginClick} open={open} onCloseModal={onCloseModal} />
            {showToast &&
                <Toast style={toastStyle}>
                    <Toast.Header>
                        <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
                        <strong className="me-auto">Error</strong>
                    </Toast.Header>
                    <Toast.Body>Sign In to continue.</Toast.Body>
                </Toast>
            }
            {showToastS & showToast2 &&
                <Toast style={toastStyle} className='close-toast'>
                    <Toast.Header >
                        <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
                        <strong className="me-auto">Message</strong>
                    </Toast.Header>
                    <Toast.Body>This Restaurant is Currently Closed.</Toast.Body>
                </Toast>
            }

            {/* {showToastS &&
                // <div className="custom-toast">
                //     <div className="custom-toast-header">
                //         <strong className="custom-toast-title">Error</strong>
                //         <button className="custom-toast-close">&times;</button>
                //     </div>
                //     <div className="custom-toast-body">
                //         Restaurant is currently close.

                //     </div>
                // </div>
                <p>Restaurant is currently close.</p>

            } */}



            <div className='restaurant-home'>
                <div class="restaurant-search">
                    <div class="row d-flex justify-content-center align-items-center">
                        <div class="col-md-8">
                            <div class="search-bar">
                                <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Restaurant Dishes"
                                    onChange={(e) => {
                                        restaurantSearch(e.target.value);
                                    }}
                                />
                                {/* <button class="btn btn-primary" >
                                    Search Food
                                </button> */}
                            </div>
                        </div>
                    </div>
                </div>
                {isLoading &&
                    <div className='mt-5' style={{ textAlign: 'center' }}>
                        <Spinner animation="border" variant="danger" />
                    </div>
                }
                {!isLoading &&
                    <div>
                        <div>
                            <div>
                                <img src={restaurant.coverPhoto} class="cover-img" />
                            </div>
                        </div>
                        <div className="restaurant-details">
                            <h1 className='restaurant-title'>{restaurant.restaurantName}</h1>
                            <p className="ratings">({restaurant.rating})
                                {renderStars(restaurant.rating)}
                            </p>
                            <p className='restaurant-distance'>
                                {/* Distance:&nbsp;
                                {haversine(userLoc.lat, userLoc.lng, restaurant?.location?.coordinates[1], restaurant?.location?.coordinates[0])} km */}
                            </p>
                            {showToastS &&

                                <p className='rest-close'>This Restaurant is Currently Closed.</p>

                            }
                            {restaurant.about &&
                                <p className="restaurant-description">
                                    <b>Restaurant Description: </b>
                                    {restaurant.about}
                                </p>
                            }
                        </div>
                        {/* <div className="special-deals">
                        <h1 className="deals-title">Special Featured Deals</h1>
                        <div className="special-section row">
                            <div>
                                <Slider {...set}>
                                    <div className="sd-card">
                                        <div className="special-card">
                                            <img src='./images/deal1.png' alt="" className="sd-img" />
                                        </div>
                                    </div>
                                    <div className="sd-card">
                                        <div className="special-card">
                                            <img src='./images/deal2.png' alt="" className="sd-img" />
                                        </div>
                                    </div>
                                    <div className="sd-card">
                                        <div className="special-card">
                                            <img src='./images/deal4.png' alt="" className="sd-img" />
                                        </div>
                                    </div>
                                    <div className="sd-card">
                                        <div className="special-card">
                                            <img src='./images/deal3.png' alt="" className="sd-img" />
                                        </div>
                                    </div>
                                </Slider>
                            </div>
                        </div>
                    </div> */}
                        <hr />
                        {/* <div className='category-deals-filter'>
                            <Slider {...set1}>
                                {restaurant?.categories?.map((category) => (
                                    <div className="cat-card">
                                        <button onClick={() => getDishesByCategories(category)} className='category-btns'>{category}</button>
                                    </div>
                                ))}
                            </Slider>
                        </div> */}

                        <div className="slider-container" ref={horiSliderRef}>
                            {restaurant?.categories?.map((category) => {
                                return (
                                    <div className="cat-card" key={category}>
                                        <button onClick={() => getDishesByCategories(category)} className='category-btns'>{category}</button>
                                    </div>
                                );

                            })}
                        </div>
                        <hr className='hr-line' />
                        <div className="content-wrapper" >
                            <div className="myvertical-slider" ref={sliderRef} slidesToShow={slidesToShow}>

                                <div className="myslider-content category-deal-content">
                                    {dishes.map((dish) => (
                                        <div className="category-deal-card" onClick={() =>
                                            openModal(dish)
                                        }>
                                            <div className="plus-sign"><IoIosAdd className='plus-ico' /></div>
                                            <div className="image-container">
                                                <img src={dish.picture} className="category-deal-img" />
                                            </div>
                                            <div className="category-deal-card-body">
                                                <p className='food-nam'>{dish?.dishName?.substring(0, 25)}</p>
                                                {/* {dish?.price ? (
                                                    <span>
                                                        {dish.price !== dish.fullPrice && storedCountry === "PK" ? (
                                                            <>
                                                                <del className='cut-price'>
                                                                    {ConvertPKRtoUSD(dish.fullPrice)}
                                                                </del>
                                                                <p className='price-food'>
                                                                    {ConvertPKRtoUSD(dish.price)}
                                                                </p>
                                                            </>
                                                        ) : (
                                                            <p className='price-food'>
                                                                {ConvertPKRtoUSD(dish.price)}
                                                            </p>
                                                        )}
                                                    </span>
                                                ) : (
                                                    <span>
                                                        {dish?.sizes?.[0]?.price !== dish?.sizes?.[0]?.fullPrice && storedCountry === "PK" ? (
                                                            <>
                                                                <del className='cut-price'>
                                                                    {ConvertPKRtoUSD(dish?.sizes?.[0]?.fullPrice)}
                                                                </del>
                                                                <p className='price-food'>
                                                                    {ConvertPKRtoUSD(dish?.sizes?.[0]?.price)}
                                                                </p>
                                                            </>
                                                        ) : (
                                                            <p className='price-food'>
                                                                {ConvertPKRtoUSD(dish?.sizes?.[0]?.price)}
                                                            </p>
                                                        )}
                                                    </span>
                                                )} */}
                                                {dish?.price ? (
                                                    <span>
                                                        {storedCountry === "PK" ? (
                                                            dish.fullPrice > dish.price ? (
                                                                <>
                                                                    <del className='cut-price'>
                                                                        {ConvertPKRtoUSD(dish.fullPrice)}
                                                                    </del>
                                                                    <p className='price-food'>
                                                                        {ConvertPKRtoUSD(dish.price)}
                                                                    </p>
                                                                </>
                                                            ) : (
                                                                <p className='price-food'>
                                                                    {ConvertPKRtoUSD(dish.price)}
                                                                </p>
                                                            )
                                                        ) : (
                                                            <p className='price-food'>
                                                                {ConvertPKRtoUSD(dish.price)}
                                                            </p>
                                                        )}
                                                    </span>
                                                ) : (
                                                    <span>
                                                        {storedCountry === "PK" ? (
                                                            dish?.sizes?.[0]?.fullPrice > dish?.sizes?.[0]?.price ? (
                                                                <>
                                                                    <del className='cut-price'>
                                                                        {ConvertPKRtoUSD(dish?.sizes?.[0]?.fullPrice)}
                                                                    </del>
                                                                    <p className='price-food'>
                                                                        {ConvertPKRtoUSD(dish?.sizes?.[0]?.price)}
                                                                    </p>
                                                                </>
                                                            ) : (
                                                                <p className='price-food'>
                                                                    {ConvertPKRtoUSD(dish?.sizes?.[0]?.price)}
                                                                </p>
                                                            )
                                                        ) : (
                                                            <p className='price-food'>
                                                                {ConvertPKRtoUSD(dish?.sizes?.[0]?.price)}
                                                            </p>
                                                        )}
                                                    </span>
                                                )}

                                            </div>
                                            {dish?.description?.length > 0 && <div className="category-description-card-body">
                                                {dish?.description?.substring(0, 30) + '...'}
                                            </div>
                                            }
                                        </div>
                                    ))}

                                </div>

                            </div>

                            <div>
                                <DetailsPopup
                                    isOpen={isModalOpen}
                                    onClose={closeModal}
                                    dishDetails={selectedDish}
                                    addToCart={addToCart}
                                    isTaxPayable={isTaxpayable}
                                />
                            </div>

                            <div className="cart-section">
                                <CartComponent handleLoginClick={handleLoginClick} itemsInCart={cartItems} addToCart={addToCart} giftInCart={giftItems} />
                            </div>
                        </div>

                    </div>
                }

            </div>
            <Footer />
        </div>
    )
}

export default RestaurantHome;
